import React from 'react'
import { Box, Button, Flex, Image, Link, Spacer } from '@chakra-ui/react'
import Twitter from './assets/social-media-icons/twitter.png'
import Opensea from './assets/social-media-icons/opensea.png'
import Etherscan from './assets/social-media-icons/etherscan.png'

const NavBar = ({ accounts, setAccounts }) => {
    const isConnected = Boolean(accounts[0])

    async function connectAccount() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: 'eth_requestAccounts',
            })
            setAccounts(accounts)
        }
    }

    return (
        <Flex justify="space-between" align="center" padding="30px">
            {/* Left Side - Social Media Icons */}
            <Flex justify="space-around" width="20%" padding="0 75px" style={{ filter: 'brightness(0) invert(1)' }}>
                <Link href="https://twitter.com/ToastOfChaosNFT">
                    <Image src={Twitter} boxSize="42px" margin="0 5px" />
                </Link>
                <Link href="https://opensea.io/collection/toast-of-chaos">
                    <Image src={Opensea} boxSize="42px" margin="0 5px" />
                </Link>
                <Link href="https://etherscan.io/address/0x9f31D3239f976dBE7D0A871Ca23b7c3ffDa50584">
                    <Image src={Etherscan} boxSize="42px" margin="0 5px" />
                </Link>
            </Flex>

            <Flex justify="space-around" align="center" width="40%" padding="30px">
                {isConnected ? (
                    <Box margin="0 15px">Connected</Box>
                ) : (
                    <Button
                        backgroundColor="#754d42"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        margin="0 15px"
                        onClick={connectAccount}
                    >
                        Connect
                    </Button>
                )}
            </Flex>
        </Flex>
    )
}

export default NavBar
