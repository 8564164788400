import React, { useEffect } from 'react'
import { useState } from 'react'
import { ethers, BigNumber } from 'ethers'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import toastOfChaosNFT from './ToastOfChaosNFT.json'
import Select from 'react-select'

const toastOfChaosNFTAddress = '0x9f31D3239f976dBE7D0A871Ca23b7c3ffDa50584'
const provider = new ethers.providers.Web3Provider(window.ethereum)
const signer = provider.getSigner()
const contract = new ethers.Contract(toastOfChaosNFTAddress, toastOfChaosNFT.abi, signer)
let isFreeMintClaimed = localStorage.getItem('isFreeMintClaimed') === 'true'

const MainMint = ({ accounts, setAccounts }) => {
    const [mintAmount, setMintAmount] = useState(1)
    const [isPaused, setIsPaused] = useState(false)
    const isConnected = Boolean(accounts[0])

    useEffect(() => {
        contract.paused().then((data) => setIsPaused(data))
    }, [])

    async function handleMint() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const contract = new ethers.Contract(toastOfChaosNFTAddress, toastOfChaosNFT.abi, signer)
            try {
                if (mintAmount.value == 1 && !isFreeMintClaimed) {
                    console.log(mintAmount.value)
                    // Add free mint here
                    const response = await contract.freeMintClaim(BigNumber.from(mintAmount.value))
                    isFreeMintClaimed = true
                    localStorage.setItem('isFreeMintClaimed', 'true')
                    return
                }
                const response = await contract.mint(BigNumber.from(mintAmount.value), {
                    value: ethers.utils.parseEther((0.003 * mintAmount.value).toString()),
                })
                console.log('Response: ', response)
            } catch (err) {
                console.log('Error: ', err, mintAmount.value)
            }
        }
    }

    const options = [
        { value: '1', label: '1' },
        { value: '3', label: '3' },
        { value: '5', label: '5' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
    ]

    const customStyles = {
        option: (provided) => ({
            ...provided,
            color: 'black',
        }),
        control: (provided) => ({
            ...provided,
            color: 'black',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
    }

    return (
        <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
            <Box width="820px">
                <div>
                    <Text fontSize="48px" textShadow="0 5px #000000">
                        Toast of Chaos
                    </Text>
                    <Text fontSize="30px" textShadow="0 2px 2px #000000" fontFamily="VT323" letterSpacing="-5.5%">
                        7777 Toasts on Ethereum waiting to get baked. 1 Mint is FREE. Rest are 0.003 ETH
                    </Text>
                </div>
                {isConnected && !isPaused ? (
                    <div>
                        <Text fontSize="30px" textShadow="0 2px 2px #000000" fontFamily="VT323">
                            How many do you want to mint?
                        </Text>
                        <Flex align="center" justify="center">
                            <Select
                                styles={customStyles}
                                options={options}
                                defaultValue={mintAmount}
                                onChange={setMintAmount}
                            />
                        </Flex>
                        <Button
                            backgroundColor="#583f3a"
                            borderRadius="5px"
                            boxShadow="0px 2px 2px 1px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="inherit"
                            padding="15px"
                            marginTop="10px"
                            onClick={handleMint}
                        >
                            MINT NOW
                        </Button>
                    </div>
                ) : isPaused ? (
                    <Text
                        marginTop="70px"
                        fontSize="30px"
                        fontFamily="VT323"
                        textShadow="0 3px #000000"
                        color="#ff7f00"
                    >
                        Mint has not started
                    </Text>
                ) : (
                    <Text
                        marginTop="70px"
                        fontSize="30px"
                        fontFamily="VT323"
                        textShadow="0 3px #000000"
                        color="#ff7f00"
                    >
                        You must be connected to mint
                    </Text>
                )}
            </Box>
        </Flex>
    )
}

export default MainMint
